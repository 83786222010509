<template>
  <dialog class="rounded-lg mt-10 sm:mt-24 lg:mt-32 p-2 md:p-4 dialog" id="dialog" ref="dialog">
    <div ref="inner" class="inner relative" id="inner">
      <button class="absolute top-0 right-0 w-6 h-6" @click="close"><img class="absolute top-0 right-0 w-6 h-6"
                                  src="~/assets/img/icons/close.svg"
                                  :alt="$t('close')"/></button>
      <slot></slot>
    </div>
  </dialog>
</template>

<script setup>

  import {useI18n} from "vue-i18n";
  const emit = defineEmits(['close']);
  const close = () => {
    if (! canClose.value) {
      return;
    }
    emit('close');
  }

  const checkCancel = (e) => {
    if (e.target === 'dialog') {
      close()
    }
  }

  const dialog = ref(null);
  const {t} = useI18n();
  const props = defineProps({
    open : {
      type: Boolean,
      default: false
    },
    canClose: {
      type: Boolean,
      default: true
    }
  })

  const {canClose} = toRefs(props);

  const closeDialog = () => {
    if (! canClose.value) {
      return;
    }

    emit('close')
    dialog.value.classList.remove('dialog-in');
    window.setTimeout(() => {
          if (dialog.value) {
            dialog.value.close();
          }
        }, 200);
  }

  let prematureClose = false;

  onMounted(() => {
    dialog.value.addEventListener('click', (e) => {
      if (e.target.id === 'dialog') {
       closeDialog()
      }
    })
    // if a user cancels out of a file input select, we want to reopen the dialog
    dialog.value.addEventListener('cancel', (e) => {
      if (e.target !== dialog.value) {
       prematureClose = true;
      }
    })
    dialog.value.addEventListener('close', (e) => {
      if (prematureClose) {
        dialog.value.showModal();
        prematureClose = false;
        return;
      }
    })
  })

  watch(() => props.open, (val, oldValue) => {
    if (! dialog.value) {
      return;
    }

    if (val === true) {
      dialog.value.showModal();
      window.setTimeout(() => {
        dialog.value.classList.add('dialog-in')
      }, 0.5);
    }
    else {
      closeDialog()
    }
  });

</script>

<style scoped>
.inner {
    width: 100%;
    height: 100%;
    padding: 1rem
  }
</style>